import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel, CircularProgress, IconButton, Fade } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";
import '@fontsource/roboto'; // Ensure Roboto font is loaded
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f2f4fa',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    body1: {
      fontSize: '.975rem',
    },
  },
});

const UploaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: '40%',
  margin: 'auto',
}));

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
});

const TitleContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  marginBottom: '20px',
});

const FileNameDisplay = styled(Typography)({
  fontSize: '0.875rem',
  color: '#555',
  textAlign: 'center',
  marginTop: '4px',
});

const ProcessingContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
});

const PdfViewerContainer = styled(Box)({
  marginTop: '20px',
  textAlign: 'center',
});

const PdfUploader = () => {
  const navigate = useNavigate(); // Set up navigate function
  const { user, logout } = useAuth0();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [logoFileName, setLogoFileName] = useState('');
  const [footerFile, setFooterFile] = useState(null);
  const [footerFileName, setFooterFileName] = useState('');

  const [uploadStatus, setUploadStatus] = useState('');
  const [processing, setProcessing] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [title, setTitle] = useState('');
  const [candidateName, setCandidateName] = useState('');

  // Separate state for each checkbox
  const [removeCandidateInfo, setRemoveCandidateInfo] = useState(false);
  const [hideCompanyName, setHideCompanyName] = useState(false);
  const [removeSchoolNames, setRemoveSchoolNames] = useState(false);
  const MAX_LOGO_SIZE = 500 * 1024;

  // Format file name helper
  const formatFileName = (name) => {
    if (name.length <= 15) return name;
    const firstPart = name.slice(0, 10);
    const lastPart = name.slice(-10);
    return `${firstPart}...${lastPart}`;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file ? formatFileName(file.name) : '');
    setResponseText('');
    setPdfUrl(null);
  };

  const handleHeaderChange = (event) => {
    const file = event.target.files[0];
    
    if (file && file.size > MAX_LOGO_SIZE) {
      setUploadStatus('Header file size exceeds 500KB. Please choose a smaller file.');
      setLogoFile(null);
      setLogoFileName('');
     
    } else {
      setLogoFile(file);
      setLogoFileName(file ? formatFileName(file.name) : '');
      setUploadStatus('');
      
    }
  };

  const handleFooterChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > MAX_LOGO_SIZE) {
      setUploadStatus('Footer file size exceeds 500KB. Please choose a smaller file.');
      setFooterFile(null);
      setFooterFileName('');
    } else {
      setFooterFile(file);
      setFooterFileName(file ? formatFileName(file.name) : '');
      setUploadStatus('');
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  
  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a Resume to upload.');
      return;
    }
  
    setProcessing(true);
    setUploadStatus('Uploading...');
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      const testDict = { 
        output: 'text', 
        title: title, 
        name: candidateName,
        ci: removeCandidateInfo,
        cn: hideCompanyName,
        sn: removeSchoolNames,
        user_id: user.email
      };
  
      // Read logo and footer files asynchronously
      if (logoFile) {
        testDict['logo'] = await readFileAsDataURL(logoFile);
      }
  
      if (footerFile) {
        testDict['footer'] = await readFileAsDataURL(footerFile);
      }
  
      // Append testDict after both logo and footer are set
      formData.append('test', JSON.stringify(testDict));
  
      // Proceed with the upload
      await uploadFile(formData);
    } catch (error) {
      console.error('Error uploading the file:', error);
      setUploadStatus('Processing Failure');
    } 
  };
  
  

  const uploadFile = async (formData) => {
    try {
      const response = await axios.post(
        'https://f7vx015btl.execute-api.us-east-2.amazonaws.com/Prod/upload/',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' }
      );

      if (response.status === 200) {
        setUploadStatus('Processing Has Begun');
        setProcessing(true);
        const contentType = response.headers['content-type'];

        if (contentType === 'application/pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          setPdfUrl(url);
          setResponseText('');
        } else if (contentType.startsWith('text/')) {
          const reader = new FileReader();
          reader.onload = () => setResponseText(reader.result);
          reader.readAsText(response.data);
        } else if (contentType === 'application/json') {
          const text = await response.data.text();
          const parsedBody = JSON.parse(text);
          pollForText(parsedBody.request_id);
        }
      } else {
        setUploadStatus('Processing Failure');
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
      setUploadStatus('Processing Failure');
      setProcessing(false);
    } 
  };

  const pollForText = async (requestId) => {
    setProcessing(true);
    setUploadStatus('Waiting for results')

    const formData = new FormData();
    formData.append(
      'test',
      JSON.stringify({ mode: 'get_output', request_id: requestId, user_id: user.email, output: 'text' })
    );

    try {
      const response = await axios.post('https://f7vx015btl.execute-api.us-east-2.amazonaws.com/Prod/upload/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const contentType = response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        const jsonData = response.data;
        console.log('Received JSON data:', jsonData); // Log the JSON data

        setProcessing(false);
        setUploadStatus('Data fetched successfully!');

        // Check if data exists, then navigate
        if (jsonData) {
          navigate('/split-screen', { state: { jsonData } });
        }
      } else if (response.data === 'FAILURE') {
        setUploadStatus('Processing Failed');
        setProcessing(false);
      } else {
        setTimeout(() => pollForText(requestId), 8000);
      }
    } catch (error) {
      console.error('Error polling for data:', error);
      setTimeout(() => pollForText(requestId), 8000);
    }
  };
    /*
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/split-screen')}
            sx={{ mt: 2 }}
          >
            Editor
          </Button>*/
  return (
    <ThemeProvider theme={theme}>
      <UploaderContainer>
        <HeaderContainer>
          <TitleContainer>
            <Typography variant="h5"><b>Resume Brander</b></Typography>
          </TitleContainer>
      
          <IconButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} color="error">
            <FontAwesomeIcon icon={faPowerOff} />
          </IconButton>
        </HeaderContainer>

        {/* Upload buttons */}
        <ButtonContainer>
          <Box textAlign="center">
            <Button
              variant="outlined"
              component="label"
              sx={{ textTransform: 'none', backgroundColor: 'white' }}
            >
              Upload Resume (pdf)
              <input type="file" accept="application/pdf" hidden onChange={handleFileChange} />
            </Button>
            {selectedFileName && <FileNameDisplay>{selectedFileName}</FileNameDisplay>}
          </Box>

          <Box textAlign="center">
            <Button
              variant="outlined"
              component="label"
              sx={{ textTransform: 'none', backgroundColor: 'white' }}
            >
              Upload Header (png/jpg)
              <input type="file" accept="image/*" hidden onChange={handleHeaderChange} />
            </Button>
            {logoFileName && <FileNameDisplay>{logoFileName}</FileNameDisplay>}
          </Box>

          <Box textAlign="center">
            <Button
              variant="outlined"
              component="label"
              sx={{ textTransform: 'none', backgroundColor: 'white' }}
            >
              Upload Footer (png/jpg)
              <input type="file" accept="image/*" hidden onChange={handleFooterChange} />
            </Button>
            {footerFileName && <FileNameDisplay>{footerFileName}</FileNameDisplay>}
          </Box>
        </ButtonContainer>

        {/* Input Fields */}
        <Box mb={2}>
          <TextField
            label="Name on Resume"
            value={candidateName}
            onChange={(e) => setCandidateName(e.target.value)}
            fullWidth
            margin="normal"
            sx={{ bgcolor: 'background.paper' }}
          />
          <TextField
            label="Account Rep Info"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
            sx={{ bgcolor: 'background.paper' }}
          />
        </Box>

        {/* Checkboxes with independent states */}
        <FormControlLabel
          control={<Checkbox checked={removeCandidateInfo} onChange={() => setRemoveCandidateInfo(!removeCandidateInfo)} />}
          label={
            <Typography>
              Remove Candidate Name and Contact Information
            </Typography>
          }
        />
        <br />
        <FormControlLabel
          control={<Checkbox checked={hideCompanyName} onChange={() => setHideCompanyName(!hideCompanyName)} />}
          label={
            <Typography>
              Replace Company Name with generic description
            </Typography>
          }
        />
        <br />
        <FormControlLabel
          control={<Checkbox checked={removeSchoolNames} onChange={() => setRemoveSchoolNames(!removeSchoolNames)} />}
          label={
            <Typography>
              Remove School Names in Education
            </Typography>
          }
        />
        
        <Box textAlign="center" mt={2}>
          <Button
            variant="contained"
            onClick={handleUpload}
            color="success"
            sx={{ width: '50%', maxWidth: '350px', textTransform: 'none' }}
          >
            Upload Resume
          </Button>
        </Box>

        {processing && (
          <ProcessingContainer>
            <CircularProgress size={40} thickness={4.5} />
          </ProcessingContainer>
        )}

        <Fade in={!!uploadStatus}>
          <Typography variant="body2" color="textSecondary" marginTop={2} textAlign="center">
            {uploadStatus}
          </Typography>
        </Fade>

        {responseText && (
          <TextField
            variant="outlined"
            multiline
            rows={10}
            value={responseText}
            onChange={(e) => setResponseText(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}

        {pdfUrl && (
          <PdfViewerContainer>
            <iframe src={pdfUrl} width="100%" height="500px" title="Processed Resume"></iframe>
            <Button href={pdfUrl} download="resume.pdf" variant="contained" color="secondary">
              Download PDF
            </Button>
          </PdfViewerContainer>
        )}
      </UploaderContainer>
    </ThemeProvider>
  );
};

export default PdfUploader;
