// src/components/Login.js

import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import axios from "axios";

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, user, logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const checkAuthorization = async () => {
      if (isAuthenticated && user) {
        try {
          // Get the access token for secure communication
          //const token = await getAccessTokenSilently();
          
          const formData = new FormData();
          formData.append(
            'test',
            JSON.stringify({ mode: 'authorize', user_id: user.email})
          );
          const response = await axios.post(
            'https://f7vx015btl.execute-api.us-east-2.amazonaws.com/Prod/upload/', // Replace with your API Gateway endpoint
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
              
            }
          );

          // Check if access is granted
          if (!response.data.accessGranted) {
            alert("Access Denied. Reach out to don@myhireway.com for help.");
            logout({ returnTo: window.location.origin });
          }
        } catch (error) {
          console.error("Error checking authorization:", error);
          alert("An error occurred while verifying access. Please try again.");
          logout({ returnTo: window.location.origin });
        }
      }
    };

    checkAuthorization();
  }, [isAuthenticated, user, logout, getAccessTokenSilently]);

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100vh" // Full viewport height to center content vertically
      bgcolor="#f2f4fa"
    >
      <Card sx={{ maxWidth: 400, textAlign: 'center', boxShadow: 3, borderRadius: 2, p: 2 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Welcome to Resume Brander
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            Please login to continue
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => loginWithRedirect()}
            sx={{ mt: 2, textTransform: 'none' }}
          >
            Log In
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginButton;
